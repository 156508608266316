.lightHeader___26Jy8 {
  background-color: #ffffff;
  /* display: flex;
  flex-direction: row; */
}

.logo___5HgWG {
  float: left;
  width: 128px;
  height: 39px;
  margin: 24px 12px 6px 0;
  cursor: pointer;
}

.flagsSelect___33oqy {
  margin-top: 18px;
}

.flagsSelect___33oqy * {
  line-height: 18px !important;
}

.flagsSelect___33oqy button {
  border: none;
  padding: 5px 5px;
  width: 60px;
}

.flagsSelect___33oqy li {
  padding: 4px 20px 4px 10px;
}

.flagsSelect___33oqy ul {
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
}
