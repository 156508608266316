html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Poppins', sans-serif;

  background-color: #f4f4f4 !important;
  scroll-behavior: smooth;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
}
